import { UNITS } from "./units";

const dateFormat = new Intl.DateTimeFormat("en-US", {
  // dateStyle: "medium",
  year: "numeric",
  month: "long",
  day: "numeric"
});

const timeFormat = new Intl.DateTimeFormat("en-US", {
  hour: "numeric",
  minute: "numeric",
  second: "numeric"
});

export const timeComponents = (value, units) => {
  const now = Date.now();
  let factor = 1;
  switch (units) {
    case UNITS.SECONDS:
      factor = 1000;
      break;
    case UNITS.MINUTES:
      factor = 60000;
      break;
    case UNITS.HOURS:
      factor = 3600000;
      break;
    default:
      break;
  }
  const newTime = now + value * factor;

  try {
    return {
      date: dateFormat.format(new Date(newTime)),
      time: timeFormat.format(new Date(newTime)),
      timestamp: newTime
    };
  } catch {
    return {
      date: "???",
      time: "???",
      timestamp: newTime
    };
  }
};
