import React, { useReducer, useEffect } from "react";
import "./App.css";
import { InputNumber, Radio } from "element-react";
import styled from "styled-components";
import timeFromNowReducer, { initialState } from "./lib/reducers";
import { UNITS } from "./lib/units";
import actions from "./lib/actionTypes";

import "element-theme-dark";

const Q = styled.div`
  margin: 0.5em auto;
  font-size: 1em;
  font-weight: bold;
`;
const H1 = styled.h1`
  display: inline;
  font-size: 1em;
`;

const NumberInput = styled.input`
  position: relative;
  top: -3px;
  height: 2em;
  border-radius: 0.3em;
  font-weight: bold;
  font-size: 2em;
  text-align: center;
  outline: none;
  width: 10em;
`;

const ResultSection = styled.div`
  margin-top: 1em;
`;

const DatePortion = styled.div`
  margin-top: 1em;
  font-family: Courier New;
  color: #555;
`;
const TimePortion = styled.div`
  font-family: Courier New;
  font-weight: bold;
  color: #555;
`;

function App() {
  const [state, dispatch] = useReducer(timeFromNowReducer, initialState);

  const setUnits = units =>
    dispatch({
      type: actions.SET_UNITS,
      units
    });

  const setValue = value =>
    dispatch({
      type: actions.SET_VALUE,
      value
    });

  const refresh = () => dispatch({ type: actions.REFRESH });

  useEffect(() => {
    const intervalId = setInterval(refresh, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <div>
          <H1>What time will it be in</H1> &nbsp;
          <div>
            <NumberInput
              type="number"
              value={"" + state.value}
              onChange={e => setValue(e.target.value)}
            />
          </div>
          &nbsp;
          <Radio.Group value={state.units} onChange={setUnits}>
            <Radio.Button value={UNITS.MILLIS} />
            <Radio.Button value={UNITS.SECONDS} />
            <Radio.Button value={UNITS.MINUTES} />
            <Radio.Button value={UNITS.HOURS} />
          </Radio.Group>
        </div>
        <Q>?</Q>
        <ResultSection>
          Answer:
          <DatePortion>{state.date}</DatePortion>
          <TimePortion>{state.time}</TimePortion>
        </ResultSection>
        {/* <p>
          The time from now in {state.value} {state.units} will be
        </p> */}
      </header>
    </div>
  );
}

export default App;
