import { timeComponents } from "./time.js";
import actions from "./actionTypes";

export const initialState = {
  units: "minutes",
  value: 0,
  timestamp: 0,
  date: "",
  time: ""
};

const handleSetValue = (state, action) => {
  let val = state.value;
  //   console.log(`state ${state.value} action ${action.value}`);
  try {
    if (action.value === "") {
      val = 0;
    } else {
      const parsed = parseInt(action.value);
      if (!isNaN(parsed)) {
        val = parsed;
      }
    }
  } catch (e) {
    console.log("Error! " + e.message);
    // do nothing (keep current value)
  }
  //   console.log(`val ${state.val}`);
  return {
    ...state,
    ...timeComponents(val, state.units),
    value: val
  };
};

const handleSetUnits = (state, action) => ({
  ...state,
  ...timeComponents(state.value, action.units),
  units: action.units
});

const handleRefresh = (state, action) => ({
  ...state,
  ...timeComponents(state.value, state.units)
});

export default (state, action) => {
  switch (action.type) {
    case actions.SET_UNITS:
      state = handleSetUnits(state, action);

      break;

    case actions.SET_VALUE:
      state = handleSetValue(state, action);
      break;

    case actions.REFRESH:
      state = handleRefresh(state, action);
      break;
    default:
      break;
  }
  // console.log(`state ${state}`);
  return state;
};
